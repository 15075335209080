import type { FunctionComponent } from "react";
import { Component } from "react";

export const NeumButton: FunctionComponent<{
  rounded?: boolean;
  inset?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
}> = (props) => {
  const roundedClass = props.rounded ? "rounded-full" : "rounded-lg";

  return props.inset ? (
    <div className={`p-px shadow-light-neum-down dark:shadow-dark-neum-down ${roundedClass}`}>
      <div className={`p-2 ${roundedClass}`}>{props.children}</div>
    </div>
  ) : (
    <div
      className={`w-full p-px shadow-light-neum-up dark:bg-gradient-to-br dark:from-admirerBlack-900 dark:to-admirerBlack dark:shadow-dark-neum-up  ${roundedClass}`}
    >
      {props.disabled ? (
        <div
          className={`cursor-not-allowed border-2 border-admirerBlue p-2 py-3 text-admirerBlack-100 dark:text-admirerBlack-200 ${roundedClass}`}
        >
          {props.children}
        </div>
      ) : (
        <div className={`bg-gradient-to-r from-[#61CFFF] to-[#139CFF] p-2 py-3 text-white ${roundedClass}`}>
          {props.children}
        </div>
      )}
    </div>
  );
};

export const NeumButtonDanger: FunctionComponent<{
  rounded?: boolean;
  inset?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
}> = (props) => {
  const roundedClass = props.rounded ? "rounded-full" : "rounded-lg";

  return props.inset ? (
    <div className={`p-px shadow-light-neum-down dark:shadow-dark-neum-down ${roundedClass}`}>
      <div className={`p-2 ${roundedClass}`}>{props.children}</div>
    </div>
  ) : (
    <div
      className={`h-full w-full bg-gradient-to-br from-admirerWhite-200 to-admirerWhite-300 p-px shadow-light-neum-up dark:from-admirerBlack-900 dark:to-admirerBlack dark:shadow-dark-neum-up ${roundedClass}`}
    >
      {props.disabled ? (
        <div
          className={`cursor-not-allowed border-2 border-admirerBlue p-2 py-3 text-admirerBlack-200 ${roundedClass}`}
        >
          {props.children}
        </div>
      ) : (
        <div
          className={`bg-gradient-to-r from-[#EE5959] to-[#F43D3D] p-2 py-3 font-bold text-admirerWhite ${roundedClass}`}
        >
          {props.children}
        </div>
      )}
    </div>
  );
};
class Neum extends Component<{
  rounded?: boolean;
  inset?: boolean;
  className?: string;
  active?: boolean;
  children: React.ReactNode;
}> {
  render() {
    const { rounded, inset, className, active, children } = this.props;
    const roundedClass = rounded ? "rounded-full" : "rounded-lg";
    const activeClass = active ? "border-admirerBlue border-2" : "";
    const paddingClass = rounded ? "p-px" : "p-0";
    return inset ? (
      <div className={` ${className ?? " h-full"}`}>
        <div className={`h-full w-full p-px shadow-light-neum-down dark:shadow-dark-neum-down ${roundedClass}`}>
          <div className={`relative box-border h-full w-full whitespace-pre-wrap p-0 ${roundedClass}`}>{children}</div>
        </div>
      </div>
    ) : (
      <div className={`m-0 ${className ?? ""}`}>
        <div
          className={`h-full w-full bg-gradient-to-br from-admirerWhite-200 to-admirerWhite-300 p-px shadow-light-neum-up dark:from-admirerBlack-900 dark:to-admirerBlack dark:shadow-dark-neum-up ${roundedClass} ${activeClass}`}
        >
          <div
            className={`relative box-border h-full w-full whitespace-pre-wrap bg-admirerWhite  ${paddingClass} dark:bg-admirerBlack ${roundedClass}`}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default Neum;
